import React from "react"
import Particles from 'react-particles-js';

const config = {
};


const Background = () => {
  return (
    <div className="bg">
         <Particles
          height="100vh"
          width="100vw"
            params={{
              "particles": {
                "number": {
                  "value": 25,
                  "density": {
                    "enable": true,
                    "value_area": 552.4120678362015
                  }
                },
                "color": {
                  "value": "#858585"
                },
                "shape": {
                  "type": "edge",
                  "stroke": {
                    "width": 0,
                    "color": "#858585"
                  },
                  "polygon": {
                    "nb_sides": 5
                  }
                },
                "opacity": {
                  "value": 1,
                  "random": false,
                  "anim": {
                    "enable": false,
                    "speed": 1,
                    "opacity_min": 0.1,
                    "sync": false
                  }
                },
                "size": {
                  "value": 1.5,
                  "random": false,
                  "anim": {
                    "enable": false,
                    "speed": 40,
                    "size_min": 0.1,
                    "sync": false
                  }
                },
                "line_linked": {
                  "enable": false,
                },
                "move": {
                  "enable": true,
                  "speed": 2,
                  "bounce": false,
                  "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                  }
                }
              },
              "interactivity": {
                "detect_on": "canvas",
                "events": {
                  "onhover": {
                    "enable": true,
                    "mode": "repulse"
                  },
                  "onclick": {
                    "enable": false,
                  },
                  "resize": true
                },
                "modes": {
                  "repulse": {
                    "distance": 200,
                    "duration": 1
                  }
                }
              },
              "retina_detect": true
            }} />
    </div>
  )
}

export default Background
