import React from "react"

const Footer = () => {
  return (
    <footer>
         <div className="wrap wrap--2c">
             <div>
                <h3>Contact:</h3>
                <a href="mailto:info@subspace.ai">info@subspace.ai</a>
                <br/>
                <br/>
                Subspace AI Ltd<br/>
                28 Leman Street<br/>
                E1 8ER, London<br/>
                United Kingdom<br/>
             </div>
             <div>
                <h3>Follow us:</h3>
                <a href="https://www.linkedin.com/company/subspace-ai">LinkedIn</a>
             </div>
         </div>
    </footer>
  )
}

export default Footer
