import PropTypes from "prop-types"
import React from "react"
import Reveal from 'react-reveal/Reveal';

const Header = ({ siteTitle }) => (
  <header>
    <div className="wrap">
      <Reveal effect="fadeInUp">
        <h1 className="logo">
            {siteTitle}
        </h1>
      </Reveal>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
